<template>
  <div>
    <h1>Test Chat</h1>
    <p>Messages: {{ messages.length }}</p>
    <div>
      <p>Room: {{ userID }}</p>
      <input
        v-model="userID"
        @keyup.enter="ConnectWS"
      >
    </div>
    <div>
      <p>User</p>
      <input
        v-model="message"
        @keyup.enter="sendMessage"
      >
    </div>
    <div>
      <p>Admin</p>
      <input
        v-model="messageAd"
        @keyup.enter="sendAdmin"
      >
    </div>
    <div>
      <p>File</p>
      <input
        type="file"
        @change="handleFileSelect"
      >
    </div>

    <ul>
      <li
        v-for="msg in messages"
        :key="msg.id"
      >
        {{ msg }}
        {{ msg.frome }} / {{ msg.message }}
      </li>
    </ul>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Ws from '@adonisjs/websocket-client'

export default {
  data() {
    return {
      ws: null,
      chat: null,
      message: '',
      messageAd: '',
      messages: [],
      userID: null,
    }
  },
  computed: {
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsChat() {
      return this.$store.state.app.WsChat
    },
  },

  mounted() {

  },
  methods: {
    ConnectWS() {
      const Token = localStorage.getItem('token')
      const UserData = JSON.parse(localStorage.getItem('userData'))
      if (Token && UserData) {
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        const WsChat = ws.subscribe(`chat:${UserData.userID}`)
        this.$store.commit('app/SET_WSCHAT', WsChat)

        // this.chat.on('message', message => {
        //   this.messages.push(message)
        // })

        // this.chat.on('error', error => {
        //   console.log('Chat Error', error)
        // })
      }
    },
    sendMessage() {
      if (this.message.trim() !== '') {
        const mes = {
          message_type: 'text',
          message: this.message,
          frome: 'user',
        }
        this.chat.emit('message', mes)
        this.message = ''
      }
    },
    sendAdmin() {
      if (this.messageAd.trim() !== '') {
        const mes = {
          message_type: 'text',
          message: this.messageAd,
          frome: 'admin',
        }
        this.chat.emit('message', mes)
        this.messageAd = ''
      }
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        const base64 = e.target.result
        this.sendImg(base64, file.type, file.name)
      }

      reader.readAsDataURL(file)
    },
    sendImg(base64, fileType, fileName) {
      //   console.log(base64)
      const mes = {
        message_type: 'img',
        message: { base64, fileType, fileName },
        frome: 'user',
      }
      this.chat.emit('message', mes)
    },
  },
}
</script>
